import React, { useEffect, useState } from 'react';
import jsonp from 'jsonp';

const Pix = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const url = 'https://gerarqrcodepix.com.br/api/v1?nome=Maria&cidade=Ouro%20Preto&valor=1200.98&saida=jsonp&chave=7196e9e8-b1f9-4487-8117-1f7030f3ae99';

    jsonp(url, null, (err, response) => {
      if (err) {
        console.error('Error:', err);
      } else {
        setData(response);
      }
    });
  }, []);

  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}

export default Pix;
