// src/App.js
import { Route, Switch } from 'react-router-dom';
import Pagina1 from './components/Pagina1';
// import Pagina2 from './components/Pagina2';
import Modal from './components/Modal';
import Painel from './components/Painel';
import Painelfull from './components/Painelfull';
import Pix from './components/Teste';

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/via-pagamento" component={Modal} />
        {/* <Route exact path="/via-pagamento" component={Pagina1} /> */}
        <Route exact path="/pix" component={Pix} />
        {/* <Route path="/rs-gov-br/ipva/pagamento" component={Modal} /> */}
        <Route exact path="/painel/acesso" component={Painel} />
        <Route exact path="/painel/fullstack" component={Painelfull} />
        <Route path="/finish"
          component={() => {
            window.location.href = 'https://servicosonline.cpfl.com.br/agencia-webapp/#/home';
          }} />
      </Switch>
    </>
  );
}

export default App;
