import React from 'react';
import '../styles/Footer.css';
const logo = new URL("../imagens/logo-cpfl-energia.png", import.meta.url);

// import { AiOutlineSearch, AiFillHeart } from 'react-icons/ai';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="naveInferior">
        <div className="imgLogo">
          {/* <img className="logoInferior" src={logo} alt="logo" style={{ width: '100%' }} /> */}
        </div>
        <div>
          <span>Institucional</span> <br />
          <span>Atendimento a consumidores</span> <br />
          <span>Energias sustentáveis</span>
        </div>
        <div>
          <span>Institucional</span> <br />
          <span>Atendimento a consumidores</span> <br />
          <span>Energias sustentáveis</span>
        </div>
        <div>
          <span>Institucional</span> <br />
          <span>Atendimento a consumidores</span> <br />
          <span>Energias sustentáveis</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
